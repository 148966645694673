import { CustomSelect } from "../UI/Select";
import { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import "./index.css";

const AUCTIONOPTIONS = [
  { value: "Copart", label: "Copart" },
  { value: "IAAI", label: "IAAI" },
];

const AUCTIONPRICES = [
  { value: "Copart", price: 0 },
  { value: "auction2", price: 0 },
];
const CAROPTIONS = [
  { value: "jeep", label: "ჯიპი" },
  { value: "sedan", label: "სედანი" },
];

const CARPRICES = [
  { value: "jeep", price: 150 },
  { value: "sedan", price: 0 },
];

const LOCATIONPRICES = [
  { value: "CT - Hartford", price: 1020 },
  { value: "CT - Hartford Springfield", price: 1020 },
  { value: "DE - Seaford", price: 1090 },
  { value: "IL - Chicago North", price: 1300 },
  { value: "IL - Peoria", price: 1350 },
  { value: "IL - Chicago South", price: 1300 },
  { value: "IL - Wheeling", price: 1325 },
  { value: "IL - Southern Illinois", price: 1300 },
  { value: "IN - Indianapolis", price: 1395 },
  { value: "IN - Cicero", price: 1420 },
  { value: "IN - Fort Wayne", price: 1370 },
  { value: "IN - Dyer", price: 1270 },
  { value: "KS - Kansas City East", price: 1375 },
  { value: "KS - Wichita", price: 1425 },
  { value: "KS - Kansas City", price: 1375 },
  { value: "KY - Lexington West", price: 1400 },
  { value: "KY - Lexington East", price: 1400 },
  { value: "KY - Walton", price: 1400 },
  { value: "KY - Louisville", price: 1400 },
  { value: "KY - Earlington", price: 1400 },
  { value: "ME - Lyman", price: 1225 },
  { value: "DC - Washington DC", price: 1240 },
  { value: "MD - Baltimore", price: 1075 },
  { value: "MD - Baltimore East", price: 1075 },
  { value: "MA - South Boston", price: 950 },
  { value: "MA - North Boston", price: 950 },
  { value: "MA - West Warren", price: 950 },
  { value: "MA - Freetown", price: 950 },
  { value: "International Offsite Auction", price: 1225 },
  { value: "Ml - Detroit", price: 1365 },
  { value: "Ml - Lansing", price: 1390 },
  { value: "Ml - Flint", price: 1515 },
  { value: "Ml - Ionia", price: 1440 },
  { value: "Ml - Kincheloe", price: 1640 },
  { value: "MN - Minneapolis", price: 1400 },
  { value: "MN - St. Cloud", price: 1400 },
  { value: "MN - Minneapolis North", price: 1400 },
  { value: "NH - Candia", price: 1190 },
  { value: "NJ - Glassboro East", price: 920 },
  { value: "NJ - Glassboro West", price: 870 },
  { value: "NJ - Somerville", price: 920 },
  { value: "NJ - Trenton", price: 920 },
  { value: "NY - Newburgh", price: 975 },
  { value: "NY - Syracuse", price: 1100 },
  { value: "NY - Long Island", price: 975 },
  { value: "NY - Rochester", price: 1125 },
  { value: "NY - Albany", price: 975 },
  { value: "NY - Buffalo", price: 1075 },
  { value: "NC - Raleigh", price: 1300 },
  { value: "NC - Mebane", price: 1300 },
  { value: "NC - Mocksville", price: 1300 },
  { value: "NC - Lumberton", price: 1300 },
  { value: "NC - Gastonia", price: 1300 },
  { value: "NC - Concord", price: 1300 },
  { value: "NC - Raleigh North", price: 1300 },
  { value: "OH - Columbus", price: 1220 },
  { value: "OH - Cleveland East", price: 1220 },
  { value: "OH - Cleveland West", price: 1220 },
  { value: "OH - Dayton", price: 1220 },
  { value: "PA - Philadelphia", price: 1000 },
  { value: "PA - Pittsburgh North", price: 1150 },
  { value: "PA - Harrisburg", price: 1050 },
  { value: "PA - Pittsburgh South", price: 1150 },
  { value: "PA - York Haven", price: 1000 },
  { value: "PA - Chambersburg", price: 1050 },
  { value: "PA - Altoona", price: 1150 },
  { value: "PA - Scranton", price: 1150 },
  { value: "PA - Philadelphia East", price: 1050 },
  { value: "PA - Pittsburgh West", price: 1200 },
  { value: "RI - Exeter", price: 1200 },
  { value: "SC - Columbia", price: 1300 },
  { value: "SC - Spartanburg", price: 1300 },
  { value: "SC - North Charleston", price: 1300 },
  { value: "TN - Memphis", price: 1400 },
  { value: "TN - Nashville", price: 1400 },
  { value: "TN - Knoxville", price: 1400 },
  { value: "VA - Danville", price: 1100 },
  { value: "VA - Richmond East", price: 1070 },
  { value: "VA - Richmond", price: 1070 },
  { value: "VA - Hampton", price: 1020 },
  { value: "VA - Fredericksburg", price: 1020 },
  { value: "WV - Charleston", price: 1475 },
  { value: "WI - Milwaukee", price: 1420 },
  { value: "WI - Appleton", price: 1370 },
  { value: "WI - Madison South", price: 1395 },
  { value: "WI - Milwaukee North", price: 1420 },
  { value: "WI - Milwaukee South", price: 1370 },
  { value: "CT - Hartford South", price: 1100 },
  { value: "DE - New Castle", price: 1150 },
  { value: "IL - Chicago West", price: 1400 },
  { value: "IL - Dream Rides", price: 1400 },
  { value: "IL - Lincoln", price: 1400 },
  { value: "IL - REC RIDES Online Exclusive", price: 1400 },
  { value: "IL - Specialty Division", price: 1400 },
  { value: "IL - St. Louis", price: 1400 },
  { value: "IN - Indianapolis South", price: 1400 },
  { value: "IN - South Bend", price: 1400 },
  { value: "KY - Ashland", price: 1400 },
  { value: "KY - Bowling Green", price: 1400 },
  { value: "KY - Louisville North", price: 1400 },
  { value: "KY - Paducah", price: 1400 },
  { value: "MA - Boston Shirley", price: 1075 },
  { value: "MA - Taunton", price: 1075 },
  { value: "MA - Templeton", price: 1075 },
  { value: "MD - Dundalk", price: 1075 },
  { value: "MD - Elkton", price: 1075 },
  { value: "MD - Metro DC", price: 1075 },
  { value: "ME - Online Exclusive", price: 1175 },
  { value: "ME - Portland Gorham", price: 1175 },
  { value: "Ml - Grand Rapids", price: 1500 },
  { value: "MN - Minneapolis South", price: 1475 },
  { value: "MN - Minneapolis/St. Paul", price: 1475 },
  { value: "NH - Manchester", price: 1190 },
  { value: "NJ - Avenel New Jersey", price: 920 },
  { value: "NJ - Central New Jersey", price: 920 },
  { value: "NJ - Englishtown", price: 920 },
  { value: "NJ - Port Murray", price: 920 },
  { value: "NJ - Sayreville", price: 920 },
  { value: "NJ - Southern New Jersey", price: 920 },
  { value: "OH - Akron Canton", price: 1220 },
  { value: "OH - Cincinnati", price: 1220 },
  { value: "OH - Cincinnati South", price: 1220 },
  { value: "PA - Bridgeport", price: 1250 },
  { value: "PA - Erie", price: 1250 },
  { value: "PA - Pittsburgh", price: 1250 },
  { value: "PA - York Springs", price: 1250 },
  { value: "RI - Providence", price: 1300 },
  { value: "SC - Charleston", price: 1300 },
  { value: "SC - Greenville", price: 1300 },
  { value: "SC - Lexington", price: 1300 },
  { value: "TN - Chattanooga", price: 1400 },
  { value: "VA - Culpeper", price: 1120 },
  { value: "VA - Northern Virginia", price: 1170 },
  { value: "VA - Pulaski", price: 1120 },
  { value: "VA - Roanoke", price: 1120 },
  { value: "VA - Suffolk", price: 1120 },
  { value: "VA - Tidewater", price: 1170 },
  { value: "VT - Burlington", price: 1375 },
  { value: "WI - Portage", price: 1445 },
  { value: "WV - Buckhannon", price: 1375 },
  { value: "WV - Shady Spring", price: 1375 },
  { value: "MENTONE", price: 1725 },
  { value: "ME - WINDHAM", price: 1375 },
  { value: "MI WAYLAND", price: 1375 },
];

const checkLocation = () => {
  const LOCATIONS = [];
  LOCATIONPRICES.forEach((element) => {
    LOCATIONS.push({ value: element.value, label: element.value });
  });
  return LOCATIONS;
};

const DESTINATIONOPTIONS = [{ value: "Georgia, Poti", label: "Georgia, Poti" }];
const DESTINATIONPRICES = [{ value: "Georgia, Poti", price: 0 }];

const WAREHOUSEOPTIONS = [{ value: "New Jersey", label: "New Jersey" }];
const WAREHOUSEPRICES = [{ value: "New Jersey", price: 0 }];

export const Calculator = () => {
  checkLocation();
  const [auction, setAuction] = useState();
  const [location, setLocation] = useState();
  const [destination, setDestination] = useState();
  const [carType, setCarType] = useState();
  const [warehouse, setWarehouse] = useState();
  const [price, setPrice] = useState(0);

  const calculate = () => {
    const auctionPrice = AUCTIONPRICES.find((e) => e.value === auction);
    const locationPrice = LOCATIONPRICES.find((e) => e.value === location);
    const destinationPrice = DESTINATIONPRICES.find(
      (e) => e.value === destination
    );
    const warehousePrice = WAREHOUSEPRICES.find((e) => e.value === warehouse);
    const carPrice = CARPRICES.find((e) => e.value === carType);

    if (auctionPrice || locationPrice || destinationPrice || carPrice) {
      setPrice(
        (auctionPrice?.price ?? 0) +
          (locationPrice ? locationPrice.price + 100 : 0) +
          (carPrice?.price ?? 0) +
          (warehousePrice?.price ?? 0) +
          (destinationPrice?.price ?? 0)
      );
    }
  };

  return (
    <div className="flex w-full border rounded-2xl p-1 shadow-2xl flex-col lg:flex-row gap-1">
      <div className="bg-img-left w-full lg:w-9/12 p-5 flex flex-col justify-between rounded-xl ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 sm:gap-5 text-white">
          <CustomSelect
            title={"აუქციონი"}
            options={AUCTIONOPTIONS}
            setter={setAuction}
          />
          <CustomSelect
            title="ავტომობილი"
            options={CAROPTIONS}
            setter={setCarType}
          />
          <CustomSelect
            title={"შტატი"}
            options={checkLocation()}
            setter={setLocation}
          />
          <CustomSelect
            title={"საწყობი"}
            options={WAREHOUSEOPTIONS}
            setter={setWarehouse}
          />
          <CustomSelect
            title={"საბოლოო დანიშნულება"}
            options={DESTINATIONOPTIONS}
            setter={setDestination}
          />
        </div>
        <button
          className="mt-6 bg-[#FFFFFF] border border-[#1B5E20] shadow-inner w-full p-3 text-xl rounded-full text-[#2E7D32] font-Arial font-medium hover:-translate-y-1 hover:scale-105"
          onClick={calculate}
        >
          დათვლა
        </button>
      </div>
      <div className="bg-img w-full lg:w-3/12 p-5 rounded-xl text-center text-white">
        <p>ფასი მოცემულია ფოთის პორტამდე</p>
        <span className="text-7xl text-center mt-2 mb-4">{price}$</span>
        <p className="mb-3">დეტალური ინფორმაციის მისაღებად დაგვიკავშირდით</p>
        <Flex gap={5} justifyContent={"center"}>
          <Link
            to="//api.whatsapp.com/send?phone=995555330844"
            style={{
              width: "fit-content",
              borderRadius: "10px",
            }}
          >
            <Flex
              bg={"#25D366"}
              width={"fit-content"}
              py={2}
              px={2}
              gap={3}
              borderRadius={10}
            >
              <FaWhatsapp color="#fff" size={"30"} />
            </Flex>
          </Link>
          <Link
            to="//telegram.me/+995577450906"
            style={{
              width: "fit-content",
              borderRadius: "10px",
            }}
          >
            <Flex
              bg={"#229AD4"}
              width={"fit-content"}
              py={2}
              px={2}
              gap={3}
              borderRadius={10}
              alignItems={"center"}
            >
              <FaTelegram color="#fff" size={"30"} />
            </Flex>
          </Link>
        </Flex>
      </div>
    </div>
  );
};
